import React from 'react'
import  {useState} from 'react'
import {Modal, Form, Row, Col, InputNumber, message} from "antd"
import "./SubmitForm.scss"
import {batchCompanyEmployeeInfo} from '@/api/company'

const BatchEditEmployee = ({
    isModalOpen,
	setModalOpen,
    companyId,
	checkedList = [],
    setCheckedList,
    refreshList
}) => {
    const [form] = Form.useForm()
	const [formData] = useState({})

    const handleOk = () => {
		form.submit()
	}

	const handleCancel = () => {
		setModalOpen(false)
	}

	const onSubmit = async val => {
        let params = {
            employeeIds: checkedList.map(v => v.id),
            updates: {}
        }
        Object.keys(val).forEach(k => {
            if(val[k]!== undefined || val[k]!== null || val[k]!== '') {
                params.updates[k] = val[k]
            }
        })
        await batchCompanyEmployeeInfo(companyId, params).catch(err => message.error('Batch Edit Dailed'))
        message.success('Batch Edit Success')
        refreshList()
        setModalOpen(false)
        setCheckedList([])
	}

    return (
        <Modal
			title="Info Modal"
			open={isModalOpen}
			okText="Save"
			onOk={handleOk}
			onCancel={handleCancel}
			className="submit-modal"
			width={1120}
		>
			<div className="generic-submit-form">
				<div className='select-box'>Selected <span>{checkedList.length}</span> Records</div>
				<Form className="custome-form" labelCol={{span: 8}} wrapperCol={{span: 16}} form={form} initialValues={formData} onFinish={onSubmit}>
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item name="tipRate" label="Tip Rate">
                                <InputNumber style={{width: "100%"}} placeholder="Please Input Tip Rate" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="wagePerHour" label="Wage/Hr">
                                <InputNumber style={{width: "100%"}} placeholder="Please Input Wage/Hr" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="incomePerHour" label="Income/Hr" labelCol={{span: 10}} wrapperCol={{span: 14}}>
                                <InputNumber style={{width: "100%"}} placeholder="Please Input Income/Hr" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="fixedTipCommission" label="Fixed Tip Commission" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                                <InputNumber style={{width: "100%"}} placeholder="Please Input Fixed Tip Commission" />
                            </Form.Item>
                        </Col>
                    </Row>
				</Form>
            </div>
        </Modal>
    )
}

export default BatchEditEmployee