import React, { useMemo } from 'react';
import { InputNumber } from 'antd';
import './RangeNumber.scss'

export default function RangeNumber (props) {
    const { value = [], onChange, placeholder = '', ...restField } = props
    const min = useMemo(() => {
        return value[0];
    }, [value]);
    const max = useMemo(() => {
        return value[1];
    }, [value]);
    const onChangeMin = (value) => {
        onChange([value, max])
    };
    const onChangeMax = (value) => {
        onChange([min, value])
    };
    return <div className='custome-range-number'>
        <InputNumber className='custome-range-number-input' value={min} onChange={onChangeMin} placeholder={'min ' + placeholder} {...restField}/>
        <span className='split-line'>-</span>
        <InputNumber className='custome-range-number-input' value={max} onChange={onChangeMax} placeholder={'max ' + placeholder} {...restField}/>
    </div>
}