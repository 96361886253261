// hooks/usePaginatedData.js

import { useCallback, useState } from 'react';
import service from '@/config/axios/index.js'
// import { mockdata } from './mockdata';

const usePaginatedData = (endpoint) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState({})

  const fetchData = useCallback(async (params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const queryString = new URLSearchParams(params).toString();
      const url = `${endpoint}?${queryString}`;
      const response = await service.get({url});
      // const response = mockdata

      if (!response) {
        throw new Error(`Error: ${url}`);
      }
      console.log(response, '**');
      
      setData(response.content); // Assuming the response has a 'content' field
      setTotal(response.totalElements || 1);
      setPage((response.pageable?.pageNumber || 0) + 1);
      setPageSize(response.pageable?.pageSize || 20);
      setSummary({
        totalWorkedHours: response.totalWorkedHours || 0,
        totalPaidBreakHours: response.totalPaidBreakHours || 0
      })
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [endpoint]);

  return {
    data,
    total,
    page,
    pageSize,
    fetchData,
    loading,
    error,
    summary
  };
};

export default usePaginatedData;
