// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // For React 18+
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext'; // Import the AuthProvider
import { PropertiesProvider } from './contexts/PropertiesContext'; // Import the PropertiesProvider

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <AuthProvider>
      <PropertiesProvider>
        <App />
      </PropertiesProvider>
    </AuthProvider>
  </Router>
);
