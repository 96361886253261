import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PropertiesContext } from '@/contexts/PropertiesContext';
import { useAuth } from '@/contexts/AuthContext';
import { ADMIN } from '@/utils/constants';
import { Card, Row, Col } from 'antd';
import './CompanyPage.scss'; // Import the CSS file


const defaultColSpan = {
    span: 8,
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 6,
    xxl: 4
}

const CompanyPage = () => {
    const { companyId } = useParams(); // Get companyId from URL parameters
    const { role } = useAuth();
    const { properties, loading, error, fetchProperties } = useContext(PropertiesContext); // Fetch properties from context
    const navigate = useNavigate(); // Create navigate function
    console.log(properties, '***')
    // Fetch properties when companyId changes
    useEffect(() => {
        if (companyId) {
            fetchProperties(companyId); // Fetch properties for the company with token
            console.log('companyId:', companyId); // Debugging companyId
        } else {
            console.error('companyId is undefined'); // Debugging missing companyId
        }
    }, [companyId, fetchProperties]);

    // Debugging properties
    useEffect(() => {
        console.log('Properties:', properties); // Log properties to debug
    }, [properties]);

    // Navigate to property details page
    const handleViewProperty = (propertyId) => {
        navigate(`/company/${companyId}/property/${propertyId}`);
    };

    // Navigate to company overview page
    const handleGoToOverview = () => {
        navigate(`/company/${companyId}/property`);
    };

    if (loading) return <p>Loading...</p>; // Show loading state
    if (error) return <p>Error??: {error}</p>; // Show error state

    return (
        <div className="company-page">
            <div className="content-container">
                <div className="button-list-container">
                    {
                        role === ADMIN ?
                        <button className="overview-button" onClick={handleGoToOverview}>
                            Company Overview
                        </button>
                        :null
                    }
                    <h1>Property</h1>
                    {properties.length > 0 ? (
                        <Row gutter={16}>
                            {properties.map((property) => (
                                <Col {...defaultColSpan} key={property.id}>
                                    <Card
                                        key={property.id}
                                        title={property.storeName}
                                        style={{width: '100%'}}
                                        onClick={() => handleViewProperty(property.id)}
                                    >
                                        <p>{property.address}</p>
                                        <p>{property.phoneNumber}</p>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>No properties available</p> // Show message when no properties
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompanyPage;
