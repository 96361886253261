export const rangeNumberRule = {
    validator: (_, value = []) => {
        if (typeof value[0] === 'number' &&  typeof value[1] === 'number') {
            if (value[0] > value[1]) {
                return Promise.reject(new Error('Max value should equal or bigger Min value'))
            }
        }
        return Promise.resolve();
    }
}

export const phoneRule = {
    validator: (_, value) => {
        if (value) {
            if (!/^\d{10}$/g.test(value)) {
                return Promise.reject(new Error('Phone Number Is Invalid!'))
            }
        }
        return Promise.resolve();
    }
}