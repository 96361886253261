// src/components/Auth/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { postRegister } from '@/api/auth'
import {LockOutlined, UserOutlined, MailOutlined} from '@ant-design/icons'
import {Button, Form,Input, message} from 'antd'

const Register = () => {
	const navigate = useNavigate();  // Get navigation function
	const [loading, setLoading] = useState(false)
	const handleSubmit = async (values) => {
		setLoading(true)
		const response = await postRegister(values).catch(err => {
			console.error('Login Failed', err.message)
			setLoading(false)
		})
		setLoading(false)
		// Handle successful registration
		if (response) {
			message.success(response)
			navigate('/login');  // Navigate to login page
		}
  };

  return (
    <div className="login-page">
		<div className='login-form'>
			<h2>Register</h2>
			<Form name="login" onFinish={handleSubmit}>
				<Form.Item
					name="username"
					rules={[
						{
							required: true,
							message: 'Please Input Your Username!',
						},
					]}
				>
					<Input prefix={<UserOutlined />} placeholder="Username" />
				</Form.Item>
				<Form.Item
					name="password"
					rules={[
						{
							required: true,
							message: 'Please Input Your Password!',
						},
					]}
				>
					<Input.Password prefix={<LockOutlined />} placeholder="Password" />
				</Form.Item>
				<Form.Item
					name="password2"
					rules={[
						{
							required: true,
							message: 'Please Input Your Confirm Password!',
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
								}
								return Promise.reject(new Error('The confirm password that you entered do not match!'));
							},
						})
					]}
				>
					<Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
				</Form.Item>
				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Please Input Your Email!',
						},
						{
							pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
							message: 'Please Input Email Correctly!',
						}
					]}
				>
					<Input prefix={<MailOutlined />} placeholder="Email" />
				</Form.Item>

				<Form.Item>
					<Button block type="primary" htmlType="submit" loading={loading}>Register</Button>
					or
					<Button
						type="link"
						onClick={() => {
							navigate('/login')
						}}
					>
						Login
					</Button>
				</Form.Item>
			</Form>
		</div>
	</div>
  );
};

export default Register;
