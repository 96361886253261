// src/components/Auth/UpdatePassword.js
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { postUpdatePassword } from '@/api/auth'
import { LockOutlined, MailOutlined, MessageOutlined} from '@ant-design/icons'
import {Button, Form, Input, message} from 'antd'

const UpdatePassword = () => {
	const navigate = useNavigate();  // Get navigation function
	const [loading, setLoading] = useState(false)
	const handleSubmit = async (values) => {
		setLoading(true)
		const response = await postUpdatePassword(values).catch(err => {
			console.error('Update Password Failed', err.message)
			setLoading(false)
		})
		setLoading(false)
		// Handle successful registration
		if (response) {
			message.success(response)
			navigate('/login');  // Navigate to login page
		}
	};

  return (
    <div className="login-page">
		<div className='login-form'>
			<h2>Update Password</h2>
			<Form name="login" onFinish={handleSubmit}>
				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Please Input Your Email!',
						},
						{
							pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
							message: 'Please Input Email Correctly!',
						}
					]}
				>
					<Input prefix={<MailOutlined />} placeholder="Email" />
				</Form.Item>
				<Form.Item
					name="token"
					rules={[
						{
							required: true,
							message: 'Please Input Your Token!',
						}
					]}
				>
					<Input prefix={<MessageOutlined />} placeholder="Token" />
				</Form.Item>

				<Form.Item
					name="newPassword"
					rules={[
						{
							required: true,
							message: 'Please Input Your New Password!',
						},
					]}
				>
					<Input.Password prefix={<LockOutlined />} placeholder="New Password" />
				</Form.Item>

				<Form.Item>
					<Button block type="primary" htmlType="submit" loading={loading}>Confirm</Button>
					or
					<Button
						type="link"
						onClick={() => {
							navigate('/login')
						}}
					>
						Login
					</Button>
				</Form.Item>
			</Form>
		</div>
	</div>
  );
};

export default UpdatePassword;
