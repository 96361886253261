// src/components/PropertyInfo.js
import React from 'react';
import { Form } from "antd"
import GenericTable from './GenericTable'; // Adjust the import path if necessary
import {TABLE_COL_HANDLE_TYPE, SHIFT_TYPE_DICT} from '@/utils/dict'

const PropertyInfo = ({companyId}) => {
  const [form] = Form.useForm()
  // Set the endpoint for fetching property data
  if (!companyId) {
    return <div>Error: companyId is missing.</div>;
  }

  // Set the endpoint for fetching property data
  const endpoint = `/api/company/${companyId}/property`;
  
  const columnConfig = ['storeName', 'propertyCode', {
    type: TABLE_COL_HANDLE_TYPE.ARRAY_TO_STRING,
    title: 'Shift Type',
    dataIndex: 'shiftTypeAvailable',
    key: 'shiftTypeAvailable',
  }, {
    type: TABLE_COL_HANDLE_TYPE.OBJECT_TO_STRING,
    title: 'House Ratio',
    dataIndex: 'houseAreaAvailable',
    key: 'houseAreaAvailable',
  },'address', 'phoneNumber', {
    type: TABLE_COL_HANDLE_TYPE.DEFAULT,
    title: 'Card Transaction Fee',
    dataIndex: 'cardTransactionFee',
    key: 'cardTransactionFee',
  }];

  const filtersConfig = {
		form: form, // form instance
		data: [
      {
				key: 'storeName',
				label: 'Store Name',
				name: 'storeName',
				$input: {
					type: 'text', 
          placeholder: 'Please Input Store Name'
				}
			},
			{
				key: 'shiftTypeAvailable',
				label: 'Shift Type',
				name: 'shiftTypeAvailable',
				$input: {
					type: 'select', 
          placeholder: 'Please Select Shift Type',
          options: SHIFT_TYPE_DICT
				}
			}
		]
  }

  return (
    <GenericTable
      endpoint={endpoint}
      filtersConfig={filtersConfig}
      errorMessage='Failed to fetch property information.'
      noDataMessage='No property information available'
      tableName='Property Information'
      columnConfig = {columnConfig}
      formType="PropertyInfo"
      options={{
				topDownloadBtn: false
      }}
    />
  );
};

export default PropertyInfo;
