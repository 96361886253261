import React, { useEffect, useContext } from 'react'
import { PropertiesContext } from '@/contexts/PropertiesContext';
import {Layout, theme} from "antd"
import {Outlet} from "react-router-dom"
import {PoweroffOutlined, HomeOutlined} from "@ant-design/icons"
import {Button} from "antd"
import {useNavigate, useLocation} from "react-router-dom"
import "./layout.css"
const {Header, Content} = Layout

const BasicLayout = ({companyId = 1}) => {
	const { properties, fetchProperties } = useContext(PropertiesContext); // Fetch properties from context
	useEffect(() => {
		properties.length === 0 && fetchProperties(companyId)
	}, [])
	const {
		token: {colorBgContainer, borderRadiusLG},
	} = theme.useToken()
	const navigate = useNavigate()
	let {pathname} = useLocation();
	// Handle logout by navigating to the login page
	const handleLogout = () => {
		// Perform any necessary logout actions, such as clearing tokens
		navigate(`/login?redirect=${pathname}`)
	}

	// Handle navigating to the company page
	const handleGoToCompanyPage = () => {
		navigate(`/company/${companyId}`)
	}
	
	return (
		<Layout>
			<Header
				style={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div className="layout-header-home" onClick={handleGoToCompanyPage}>
					<HomeOutlined />
					<span style={{marginLeft: "6px"}}>Company Page</span>
				</div>
				<Button type="primary" icon={<PoweroffOutlined />} onClick={handleLogout}>
					Logout
				</Button>
			</Header>
			<Content style={{padding: "0"}}>
				<div
					style={{
						minHeight: 380,
						background: colorBgContainer,
						borderRadius: borderRadiusLG,
					}}
				>
					<Outlet />
				</div>
			</Content>
		</Layout>
	)
}

export default BasicLayout
