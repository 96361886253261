import service from '@/config/axios/index.js'

export const getCompanyProperty = async companyId => {
	return await service.get({
		url: `/api/company/${companyId}/property/under-permission/all`,
	})
}

export const getCompanySale = async companyId => {
	return await service.get({
		url: `/api/company/${companyId}/shift/all`,
	})
}

export const getCompanyStock = async companyId => {
	return await service.get({
		url: `/api/company/${companyId}/stock/all`,
	})
}

export const getCompanyWorkHistory = async companyId => {
	return await service.get({
		url: `/api/company/${companyId}/work-history/all`,
	})
}

export const getCompanyEmployeeInfo = async (companyId, data) => {
	return await service.get({
		url: `/api/company/${companyId}/employee-info/all`,
		params: data
	})
}

export const getFilterEmployeeList = async (data) => {
	return await service.get({
		url: `/api/lookup/employees`,
		params: data
	})
}

export const postCalculateTips = async (companyId, shiftId) => {
	return await service.post({
		url: `/api/company/${companyId}/shift/${shiftId}/calculateTips`
	})
}

export const batchCompanyEmployeeInfo = async (companyId, data) => {
	return await service.patch({
		url: `/api/company/${companyId}/employee-info/batch`,
		data: data
	})
}