export const TABLE_COL_HANDLE_TYPE = {
    DEFAULT: 'DEFAULT',
    ARRAY_TO_STRING: 'ARRAY_TO_STRING',
    OBJECT_TO_STRING: 'OBJECT_TO_STRING',
    DATE_TO_STRING: 'DATE_TO_STRING',
    DATE_TO_STRING_DATE: 'DATE_TO_STRING_DATE',
    SORT: 'SORT'
}

export const EXPIRED_DICT = [
    {label: 'Active', value: 'true'},
    {label: 'Expired', value: 'false'},
]

export const ACTIVE_DICT = [
    {label: 'Active', value: 'true'},
    {label: 'Terminated', value: 'false'},
]

export const FOH_POSITION_LIST = [
    {label: 'Server Trainee', value: 'Server Trainee'},
    {label: 'Server Assistant', value: 'Server Assistant'},
    {label: 'Server', value: 'Server'},
    {label: 'Senior Server', value: 'Senior Server'},
    {label: 'Shift Lead', value: 'Shift Lead'},
]

export const BOH_POSITION_LIST = [
    {label: 'Cook Trainee', value: 'Cook Trainee'},
    {label: 'Cook', value: 'Cook'},
    {label: 'Sous-Chef', value: 'Sous-Chef'},
    {label: 'Chief', value: 'Chief'}
]

export const HOUSE_AREA_DICT = [
    {label: 'FOH', value: 'FOH', positionList: FOH_POSITION_LIST},
    {label: 'BOH', value: 'BOH', positionList: BOH_POSITION_LIST}
]

export const SHIFT_TYPE_DICT = [
    {label: 'Day', value: 'Day'},
    {label: 'Night', value: 'Night'},
    {label: 'All day', value: 'All Day'}
]