import {Modal, Button, Checkbox, Col, Row} from "antd"
import { useEffect, useState } from 'react'

const HideColumnModal = ({
	columns,
	defaultCheckedList,
	onChange
}) => {
	const [isModalOpen, setModalOpen] = useState(false)
	const [checkedList, setCheckedList] = useState([]);
	const options = columns.map(({ key, title }) => ({
		label: title,
		value: key,
	}));

	useEffect(() => {
		setCheckedList(defaultCheckedList)
	}, [isModalOpen])

    const handleOk = () => {
		onChange(checkedList)
		setModalOpen(false)
	}

	const handleCancel = () => {
		setModalOpen(false)
	}
	
	const checkAll = options.length === checkedList.length;
  	const indeterminate = checkedList.length > 0 && checkedList.length < options.length;
	const onCheckAllChange = (e) => {
		setCheckedList(e.target.checked ? options.map(v => v.value) : []);
	};
    return (
		<>
			<Button onClick={() => {setModalOpen(true)}}>Toggle Column</Button>
			<Modal
				title="Toggle Column"
				open={isModalOpen}
				okText="Confirm"
				onOk={handleOk}
				onCancel={handleCancel}
				width={900}
				checkedList
			>
				<div>
					<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
						Check all
					</Checkbox>
					<Checkbox.Group
						style={{width: '100%'}}
						value={checkedList}
						onChange={(value) => {
							setCheckedList(value);
						}}
					>
						 <Row>
							{
								options.map(op => (
									<Col span={6} key={op.value}>
										<Checkbox value={op.value}>{op.label}</Checkbox>
									</Col>
								))
							}
						</Row>
					</Checkbox.Group>
				</div>
			</Modal>
		</>
    )
}

export default HideColumnModal