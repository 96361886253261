import {phoneRule} from '@/components/CustomeForm/rules'
import {HOUSE_AREA_DICT, SHIFT_TYPE_DICT} from '@/utils/dict'
import {getRollAll} from '@/api/property'

export const EmployeeInfoConfig = ({propertyList, properties, storeName, houseAreaList = [], positionList = [], roleList = []}) => {
	console.log(storeName, 'storeName')
	return [
		{
			key: 'id',
			label: 'Employee Number',
			name: 'id',
			$input: {
				type: 'text',
				placeholder: 'Employee Number',
				disabled: true
			},
			labelCol: {span: 13},
			wrapperCol: {span: 11},
		},
		{
			key: 'adpEmployeeId',
			label: 'ADP Employee Id',
			name: 'adpEmployeeId',
			$input: {
				type: 'text',
				placeholder: 'ADP Employee Id'
			},
			labelCol: {span: 13},
			wrapperCol: {span: 11},
		},
		...(storeName? [{
			key: 'propertyId',
			label: 'Property Name',
			name: 'propertyId',
			$input: {
				type: 'blankelement',
				text: storeName
			}
		}] : [{
			key: 'propertyId',
			label: 'Property Name',
			name: 'propertyId',
			$input: {
				type: 'select',
				placeholder: 'Select Property Name',
				options: propertyList,
			},
			rules: [{required: true, message: 'Please Select Property Name.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
			union: {
				target: 'propertyId',
				event: 'onChange',
				callback(value, option) {
					/** do something */
					const form = this.getForm()
					let houseAreaList = []
					if (value) {
						let property = properties.find(v => v.id === value)
						if (property && property.houseAreaAvailable) {
							let keys = Object.keys(property.houseAreaAvailable)
							houseAreaList = HOUSE_AREA_DICT.filter(v => keys.includes(v.value))
						}
					}
					setTimeout(() => {
						form.setOptions('houseArea', houseAreaList)
						form.setOptions('position', [])
						form.setFieldValue('houseArea')
						form.setFieldValue('position')
					}, 100)
					getRollAll(value).then(res => {
						if (res && res.content) {
							form.setOptions('roleId', res.content.map(v => ({
								label: v.roleName, value: v.id
							})))
						} else {
							form.setOptions('roleId', [])
						}
						form.setFieldValue('roleId')
					})
				},
			},
		}]),
		{
			key: 'name',
			label: 'Employee Name',
			name: 'name',
			$input: {
				type: 'text',
				placeholder: 'Please Input Employee Name',
			},
			rules: [{required: true, message: 'Please Input Name.'}],
		},
		{
			key: 'nickName',
			label: 'Nickname',
			name: 'nickName',
			$input: {
				type: 'text',
				placeholder: 'Please Input Nickname',
			},
		},
		{
			key: 'phoneNumber',
			label: 'Phone Number',
			name: 'phoneNumber',
			$input: {
				type: 'text',
				placeholder: 'Please Enter Phone Number',
			},
			rules: [phoneRule],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'email',
			label: 'Email',
			name: 'email',
			$input: {
				type: 'text',
				placeholder: 'Please Input Email',
			},
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'houseArea',
			label: 'House Area',
			name: 'houseArea',
			$input: {
				type: 'select',
				placeholder: 'Select House Area',
				options: houseAreaList,
			},
			rules: [{required: true, message: 'Please Select House Area.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
			union: {
				target: 'houseArea',
				event: 'onChange',
				callback(value, option) {
					console.log(option)
					/** do something */
					const form = this.getForm()
					let positionList = []
					if (value) {
						positionList = option.positionList
					}
					setTimeout(() => {
						form.setOptions('position', positionList)
						form.setFieldValue('position')
					}, 100)
				},
			},
		},
		{
			key: 'position',
			label: 'Position',
			name: 'position',
			$input: {
				type: 'select',
				placeholder: 'Select Position',
				options: positionList,
			}
		},
		{
			key: 'tipRate',
			label: 'Tip Rate',
			name: 'tipRate',
			$input: {
				type: 'number',
				placeholder: 'Please Input Tip Rate',
			},
			rules: [{required: true, message: 'Please Input Tip Rate.'}],
		},
		{
			key: 'startDate',
			label: 'Start Date',
			name: 'startDate',
			$input: {
				type: 'datepicker',
				placeholder: 'Select Start Date',
			},
		},
		{
			key: 'endDate',
			label: 'End Date',
			name: 'endDate',
			$input: {
				type: 'datepicker',
				placeholder: 'Select End Date',
			},
		},
		{
			key: 'wagePerHour',
			label: 'Wage/Hr',
			name: 'wagePerHour',
			$input: {
				type: 'number',
				placeholder: 'Please Input Wage Per Hour',
			},
			rules: [{required: true, message: 'Please Input Wage Per Hour.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'fixedTipCommission',
			label: 'Fixed Tip Commission',
			name: 'fixedTipCommission',
			$input: {
				type: 'number',
				placeholder: 'Please Input Fixed Tip Commission',
			},
			rules: [{required: true, message: 'Please Input Fixed Tip Commission.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'incomePerHour',
			label: 'Income/Hr',
			name: 'incomePerHour',
			$input: {
				type: 'number',
				placeholder: 'Please Input Income/Hr',
			},
			rules: [{required: true, message: 'Please Input Income/Hr.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'fwcExpiredDate',
			label: 'Fwc Expired Date',
			name: 'fwcExpiredDate',
			$input: {
				type: 'datepicker',
				placeholder: 'Select Fwc Expired Date',
			},
			labelCol: {span: 12},
			wrapperCol: {span: 12},
		},
		{
			key: 'mastExpiredDate',
			label: 'Mast Expired Date',
			name: 'mastExpiredDate',
			$input: {
				type: 'datepicker',
				placeholder: 'Select Mast Expired Date',
			},
			labelCol: {span: 12},
			wrapperCol: {span: 12},
		},
		{
			key: 'roleId',
			label: 'Role Name',
			name: 'roleId',
			$input: {
				type: 'select',
				placeholder: 'Select Role Name',
				options: roleList,
			}
		},
		{
			key: 'note',
			label: 'Note',
			name: 'note',
			$input: {
				type: 'textarea',
				placeholder: 'Note'
			}
		}
	]
}

export const PropertyConfig = () => {
	return [
		{
			key: 'storeName',
			label: 'Store Name',
			name: 'storeName',
			$input: {
				type: 'text',
				placeholder: 'Please Input Store Name',
			},
			rules: [{required: true, message: 'Please Input Store Name.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'propertyCode',
			label: 'Property Code',
			name: 'propertyCode',
			$input: {
				type: 'text',
				placeholder: 'Please Input Property Code',
			},
			rules: [{required: true, message: 'Please Input Property Code.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'shiftTypeAvailable',
			label: 'Shift Type',
			name: 'shiftTypeAvailable',
			$input: {
				type: 'select',
				mode: 'multiple',
				placeholder: 'Select Shift Type Available',
				options: SHIFT_TYPE_DICT,
			},
			rules: [{required: true, message: 'Please Select Shift Type Available.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'address',
			label: 'Address',
			name: 'address',
			$input: {
				type: 'text',
				placeholder: 'Please Input Address',
			},
			rules: [{required: true, message: 'Please Input Address.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'phoneNumber',
			label: 'Phone Number',
			name: 'phoneNumber',
			$input: {
				type: 'text',
				placeholder: 'Please Enter Phone Number',
			},
			rules: [{required: true, message: 'Please Enter Phone Number.'}, phoneRule],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'initialCash',
			label: 'Initial Cash',
			name: 'initialCash',
			$input: {
				type: 'number',
				placeholder: 'Please Input Initial Cash',
			},
			rules: [{required: true, message: 'Please Input Initial Cash.'}],
			labelCol: {span: 11},
			wrapperCol: {span: 13},
		},
		{
			key: 'cardTransactionFee',
			label: 'Card Transaction Fee',
			name: 'cardTransactionFee',
			$input: {
				type: 'number',
				placeholder: 'Please Input Card Transaction Fee',
			},
			rules: [{required: true, message: 'Please Input Card Transaction Fee.'}],
			labelCol: {span: 14},
			wrapperCol: {span: 10},
		},
	]
}
