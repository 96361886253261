// src/components/WorkHistory.js
import React from 'react'
import {useContext} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
import {Form, Typography, Table} from 'antd'
import GenericTable from './GenericTable'
import dayjs from 'dayjs'
import {TABLE_COL_HANDLE_TYPE, HOUSE_AREA_DICT} from '@/utils/dict'

const { Text } = Typography

const WorkHistory = ({companyId, propertyId}) => {
	const {propertyList} = useContext(PropertiesContext) // Fetch properties from context
	const [form] = Form.useForm()
	const endpoint = propertyId ? `/api/company/${companyId}/property/${propertyId}/work-history` : `/api/company/${companyId}/work-history`

	const filtersConfig = {
		form: form, // form instance
		initialValues: {ShiftDate: [
			dayjs().subtract(14, 'day'), 
			dayjs()
		]},
		data: [
			...(propertyId
				? []
				: [
					{
						key: 'propertyId',
						label: 'Property Name',
						name: 'propertyId',
						$input: {
							type: 'select',
							placeholder: 'Please Select Property Name',
							options: propertyList,
						},
					},
				  ]),
			{
				key: 'houseArea',
				label: 'House Area',
				name: 'houseArea',
				$input: {
					type: 'select',
					placeholder: 'Please Select House Area',
					options: HOUSE_AREA_DICT,
				},
			},
			{
				key: 'employeeName',
				label: 'Employee Name',
				name: 'employeeName',
				$input: {
					type: 'text',
					placeholder: 'Please Input Employee Name',
				},
			},
			{
				key: 'ShiftDate',
				label: 'Shift Date',
				name: 'ShiftDate',
				$input: {
					type: 'daterange',
					placeholder: 'Shift Date',
				},
			}
		],
	}

	const columnConfig = [
		{
			title: 'Employee Id',
			dataIndex: 'employeeId',
			key: 'employeeId',
			sorter: true
		},
		{
			title: 'Employee Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			sorter: true
		},
		{
			title: 'Position',
			dataIndex: 'position',
			key: 'position',
			sorter: true
		},
		{
			title: 'Property Name',
			dataIndex: 'propertyName',
			key: 'propertyName',
			sorter: true,
			sortKey: 'property.storeName'
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DATE_TO_STRING_DATE,
			title: 'Date',
			dataIndex: 'workStartedAt',
			key: 'workStartedAt',
			sorter: true
		},
		{
			title: 'House Area',
			dataIndex: 'houseArea',
			key: 'houseArea',
			sorter: true
		},
		{
			title: 'Worked Hours',
			dataIndex: 'workedHours',
			key: 'workedHours',
			sorter: true
		},
		{
			title: 'Paid Break Hours',
			dataIndex: 'paidBreakHours',
			key: 'paidBreakHours',
			sorter: true
		},
		{
			title: 'Liquor Sale',
			dataIndex: 'liquorSale',
			key: 'liquorSale',
			sorter: true
		},
		{
			title: 'Total Tips',
			dataIndex: 'totalTips',
			key: 'totalTips',
			sorter: true
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT,
			title: 'Late',
			dataIndex: 'late',
			key: 'late',
			sorter: true
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			sorter: true
		}
	]

	const defaultParams = {
		minShiftDate: dayjs(dayjs().subtract(14, 'day').format('YYYY-MM-DD') + 'T00:00:00').utc().format(),
		maxShiftDate: dayjs(dayjs().format('YYYY-MM-DD') + 'T23:59:59').utc().format(),
	}

	return (
		<GenericTable
			endpoint={endpoint}
			filtersConfig={filtersConfig}
			columnConfig={columnConfig}
			errorMessage="Failed to fetch work history data."
			noDataMessage="No work history available"
			tableName="Work History"
			formType="WorkHistory"
			defaultParams={defaultParams}
			options={{
				showAction: false,
				showTopAction: false
            }}
			summaryFunc={
				(summary) => {
					return () => (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={2}>Total Worked Hours</Table.Summary.Cell>
								<Table.Summary.Cell index={1} colSpan={11}>
									<Text type="success">{summary.totalWorkedHours}</Text>
								</Table.Summary.Cell>
								
							</Table.Summary.Row>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={2}>Total Paid Break Hours</Table.Summary.Cell>
								<Table.Summary.Cell index={1} colSpan={11}>
									<Text type="success">{summary.totalPaidBreakHours}</Text>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</>
					)
				}
			}
		/>
	)
}

export default WorkHistory
