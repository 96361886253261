import { message } from 'antd';

const config = {
  /**
   * api请求基础路径
   */
  baseUrl: {
    // 开发环境接口前缀
    dev: 'https://pioneermgtgroup.com',
    // 生产环境接口前缀
    pro: 'https://pioneermgtgroup.com',
    // 测试环境接口前缀
    test: ''
  },

  /**
   * 接口成功返回状态码
   */
  code: 200,
  status: [200, 201],

  /**
   * 接口请求超时时间
   */
  timeout: 60000,

  /**
   * 默认接口请求类型
   * 可选值：application/x-www-form-urlencoded multipart/form-data
   */
  defaultHeaders: 'application/json'
}

const whiteList = ['/api/login', '/api/register']
const defaultRequestInterceptors = (config) => {
  if(!whiteList.includes(config.url)) {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
  }
  return config
}

const defaultResponseInterceptors = (response) => {
  if (response?.config?.responseType === 'blob') {
    // 如果是文件流，直接过
    return response
  } else if (response.data.code === config.code || config.status.includes(response.status)) {
    return response.data
  } else {
    message.error(response.data.msg || response.statusText)
  }
}

export { defaultRequestInterceptors, defaultResponseInterceptors };
export default config
