import service from '@/config/axios/index.js'

export const postLogin = async data => {
	return await service.post({
		url: '/api/login',
		data
	})
}
export const postRegister = async data => {
	return await service.post({
		url: '/api/register',
		data
	})
}
export const postForgotPassword = async data => {
	return await service.post({
		url: '/api/forgot-password',
		data
	})
}
export const postUpdatePassword = async data => {
	return await service.post({
		url: '/api/update-password',
		data
	})
}
