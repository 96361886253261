// src/App.js
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Login from '@/pages/Auth/Login/Login';
import Register from '@/pages/Auth/Register/Register';
import ForgotPassword from '@/pages/Auth/ForgotPassword/ForgotPassword';
import UpdatePassword from '@/pages/Auth/UpdatePassword/UpdatePassword';
import BasicLayout from './components/Layout/Layout';
import CompanyPage from '@/pages/CompanyPage/CompanyPage';
import PropertyPage from '@/pages/PropertyPage/PropertyPage';
import NotFound from '@/pages/NotFound'
import { useAuth } from './contexts/AuthContext'; // Import the auth context
import './styles/main.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from "dayjs/plugin/utc"

dayjs.extend(customParseFormat);
dayjs.extend(utc);

// Main App component
const App = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/updatepassword" element={<UpdatePassword />} />

      {/* nest layout component */}
      <Route path="/" element={<BasicLayout />}>
        <Route path="company/:companyId" element={<AuthRoute element={<CompanyPage />} />} />
        <Route path="company/:companyId/property/:propertyId?" element={<AuthRoute element={<PropertyPage />} />} />
      </Route>

      {/* Default Route */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="*" element={<NotFound />} /> {/* Handle unknown routes */}
    </Routes>
  );
};

// Component for protecting routes based on authentication
const AuthRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  // Get redirect pathname
  let {pathname} = useLocation();
  return isAuthenticated ? element : <Navigate to={`/login?redirect=${pathname}`} />;
};

export default App
