// src/components/Auth/ForgotPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { postForgotPassword } from '@/api/auth'
import { MailOutlined} from '@ant-design/icons'
import {Button, Form,Input, message} from 'antd'

const ForgotPassword = () => {
    const navigate = useNavigate();  // Get navigation function
    const [loading, setLoading] = useState(false)
    const handleSubmit = async (values) => {
        console.log(values)
        setLoading(true)
        const response = await postForgotPassword(values).catch(err => {
            console.error('Email Send Failed', err.message)
            setLoading(false)
        })
        setLoading(false)
        // Handle successful registration
        if (response) {
            message.success(response)
            navigate('/updatepassword');  // Navigate to login page
        }
    };

  return (
    <div className="login-page">
		<div className='login-form'>
			<h2>Forgot Password</h2>
			<Form name="login" onFinish={handleSubmit}>
				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Please Input Your Email!',
						},
						{
							pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
							message: 'Please Input Email Correctly!',
						}
					]}
				>
					<Input prefix={<MailOutlined />} placeholder="Email" />
				</Form.Item>

				<Form.Item>
					<Button block type="primary" htmlType="submit" loading={loading}>Confirm</Button>
					or
					<Button
						type="link"
						onClick={() => {
							navigate('/login')
						}}
					>
						Login
					</Button>
				</Form.Item>
			</Form>
		</div>
	</div>
  );
};

export default ForgotPassword;
