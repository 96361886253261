import axios from 'axios'
import config, { defaultRequestInterceptors, defaultResponseInterceptors } from './config'
import { message } from 'antd';

const { baseUrl } = config
export const PATH_URL = baseUrl[process.env.REACT_APP_API_PATH]

const abortControllerMap = new Map()

const axiosInstance = axios.create({
  ...config,
  baseURL: PATH_URL
})

axiosInstance.interceptors.request.use((res) => {
  const controller = new AbortController()
  const url = res.url || ''
  res.signal = controller.signal
  abortControllerMap.set(url, controller)
  return res
})

axiosInstance.interceptors.response.use(
  (res) => {
    const url = res.config.url || ''
    abortControllerMap.delete(url)
    return res
  },
  (error) => {
    console.log('err： ' + error) // for debug
    message.error(error.message)
    // token is expired and navigate to login
    // if (error.status === 401) {
    //   window.location.href = '/login'
    // }
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.request.use(defaultRequestInterceptors)
axiosInstance.interceptors.response.use(defaultResponseInterceptors)

const service = {
  request: (config) => {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptors) {
        config = config.interceptors.requestInterceptors(config)
      }

      axiosInstance
        .request(config)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  cancelRequest: (url) => {
    const urlList = Array.isArray(url) ? url : [url]
    for (const _url of urlList) {
      abortControllerMap.get(_url)?.abort()
      abortControllerMap.delete(_url)
    }
  },
  cancelAllRequest() {
    for (const [, controller] of abortControllerMap) {
      controller.abort()
    }
    abortControllerMap.clear()
  }
}

export default service
