import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Empty} from 'antd'
const NotFound = () => {
    const navigate = useNavigate()
    const backToHome = () => {
        const company = localStorage.getItem('company')
        navigate('/company/' + (company ||  '1'))
    }
    return (
        <div style={{paddingTop: '80px', textAlign:'center'}}>
            <Empty 
                imageStyle={{ height: 160 }}
                description={
                    <h3>
                        Page Not Found!
                    </h3>    
                }
            ></Empty>
            <Button type="primary" onClick={backToHome}>Back To Home</Button>
        </div>
    )
}

export default NotFound