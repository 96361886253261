import React from 'react'
import {useContext} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
import {Form} from 'antd'
import GenericTable from './GenericTable'
import {TABLE_COL_HANDLE_TYPE, SHIFT_TYPE_DICT} from '@/utils/dict'
import dayjs from 'dayjs'

const Shift = ({companyId, propertyId}) => {
	const {propertyList} = useContext(PropertiesContext) // Fetch properties from context
	const [form] = Form.useForm()
	const endpoint = propertyId ? `/api/company/${companyId}/property/${propertyId}/shift` : `/api/company/${companyId}/shift`

	const filtersConfig = {
		form: form, // form instance
		initialValues: {ShiftDate: [
			dayjs().subtract(1, 'day'), 
			dayjs()
		]},
		data: [
			{
				key: 'ShiftDate',
				label: 'Shift Date',
				name: 'ShiftDate',
				$input: {
					type: 'daterange',
					placeholder: 'shift date',
				},
			},
			{
				key: 'shiftType',
				label: 'Shift Type',
				name: 'shiftType',
				$input: {
					type: 'select',
					placeholder: 'Please Select Shift Type',
					options: SHIFT_TYPE_DICT,
				},
			},
			...(propertyId
				? []
				: [
						{
							key: 'propertyId',
							label: 'Property Name',
							name: 'propertyId',
							$input: {
								type: 'select',
								placeholder: 'Please Select Property Name',
								options: propertyList,
							},
						},
				  ]),
		],
	}

	const columnConfig = [
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT, 
			title: 'Store Name', 
			dataIndex: 'propertyName', 
			key: 'propertyName',
			sorter: true,
			sortKey: 'property.storeName'
		},
		{
			title: 'Property Code',
			dataIndex: 'propertyCode',
			key: 'propertyCode',
			sorter: true,
			sortKey: 'property.propertyCode'
		},
		'shiftSupervisor',
		'shiftType',
		{
			type: TABLE_COL_HANDLE_TYPE.DATE_TO_STRING,
			title: 'Shift Date',
			dataIndex: 'shiftDate',
			key: 'shiftDate',
			sorter: true
		},
		'cashSale',
		'cashTip',
		'creditSale',
		'creditTip',
		'shiftTotalCash',
		'shiftTotalSales',
		'shiftTotalTips'
	]

	const defaultParams = {
		minShiftDate: dayjs(dayjs().subtract(1, 'day').format('YYYY-MM-DD') + 'T00:00:00').utc().format(),
		maxShiftDate: dayjs(dayjs().format('YYYY-MM-DD') + 'T23:59:59').utc().format(),
	}

	const rowClassName = record => {
		return record.isSubmitted ? 'high-light-row' : ''
	}

	return (
		<GenericTable
			endpoint={endpoint}
			filtersConfig={filtersConfig}
			columnConfig={columnConfig} // Need Hide Columns
			errorMessage="Failed to fetch stock information."
			noDataMessage="No stock information available"
			tableName="Shift"
			formType="Shift"
			defaultParams={defaultParams}
			rowClassName={rowClassName}
			options={{
                topDownloadBtn: false,
            }}
		/>
	)
}

export default Shift
