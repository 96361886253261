// src/components/EmployeeInfo.js
import React from 'react'
import {useContext} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
import { useAuth } from '@/contexts/AuthContext';
import {Form} from 'antd'
import GenericTable from './GenericTable' // Adjust the import path if necessary
import {TABLE_COL_HANDLE_TYPE, ACTIVE_DICT, EXPIRED_DICT, HOUSE_AREA_DICT, FOH_POSITION_LIST, BOH_POSITION_LIST} from '@/utils/dict'
import { ADMIN } from '@/utils/constants';

const EmployeeInfo = ({companyId, propertyId}) => {
	const [form] = Form.useForm()
	const {propertyList} = useContext(PropertiesContext) // Fetch properties from context
	const { role } = useAuth();
	const endpoint = propertyId ? `/api/company/${companyId}/property/${propertyId}/employee-info` : `/api/company/${companyId}/employee-info`
	const positionList = [
		{
			label: <span>FOH</span>,
			title: 'FOH',
			options: FOH_POSITION_LIST,
		},
		{
			label: <span>BOH</span>,
			title: 'BOH',
			options: BOH_POSITION_LIST,
		},
	]
	const filtersConfig = {
		form: form, // form instance
		initialValues: {
			propertyId: propertyId || undefined,
		},
		data: [
			...(!propertyId ? [
			{
				key: 'propertyId',
				label: 'Property Name',
				name: 'propertyId',
				$input: {
					type: 'select',
					placeholder: 'Please Select Property Name',
					options: propertyList,
					allowClear: true
				},
			}]:[]),
			{
				key: 'employeeName',
				label: 'Employee Name',
				name: 'employeeName',
				$input: {
					type: 'text',
					placeholder: 'Please Input Employee Name',
				},
			},
			{
				key: 'uerId',
				label: 'Phone Number',
				name: 'uerId',
				$input: {
					type: 'text',
					placeholder: 'Please Enter Phone Number',
				},
			},
			{
				key: 'email',
				label: 'Email',
				name: 'email',
				$input: {
					type: 'text',
					placeholder: 'Please Input Email',
				},
			},
			{
				key: 'isActive',
				label: 'Status',
				name: 'isActive',
				$input: {
					type: 'select',
					placeholder: 'Select Status',
					options: ACTIVE_DICT,
					allowClear: true
				},
			},
			{
				key: 'isFWCExpired',
				label: 'FWC Status',
				name: 'isFWCExpired',
				$input: {
					type: 'select',
					placeholder: 'Select FWC Status',
					options: EXPIRED_DICT,
					allowClear: true
				},
			},
			{
				key: 'isMASTExpired',
				label: 'MAST Status',
				name: 'isMASTExpired',
				$input: {
					type: 'select',
					placeholder: 'Select MAST Status',
					options: EXPIRED_DICT,
					allowClear: true
				},
			},
			{
				key: 'houseArea',
				label: 'House Area',
				name: 'houseArea',
				$input: {
					type: 'select',
					placeholder: 'Select House Area',
					options: HOUSE_AREA_DICT,
					allowClear: true
				},
			},
			{
				key: 'position',
				label: 'Position',
				name: 'position',
				$input: {
					type: 'select',
					placeholder: 'Select Position',
					options: positionList,
					allowClear: true
				},
			}
		],
	}

	const columnConfig = [
		{
			title: 'Employee Number',
			dataIndex: 'id',
			key: 'id',
			sorter: true
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT,
			title: 'Store',
			dataIndex: 'propertyName',
			key: 'propertyName',
			sorter: true
		},
		{
			title: 'Employee Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true
		},
		{
			title: 'NickName',
			dataIndex: 'nickName',
			key: 'nickName',
			sorter: true
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			sorter: true,
			sortKey: 'user.phoneNumber'
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT,
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sorter: true
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT,
			title: 'FWC Status',
			dataIndex: 'fwcStatus',
			key: 'fwcStatus',
			sorter: true
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT,
			title: 'MAST Status',
			dataIndex: 'mastStatus',
			key: 'mastStatus',
			sorter: true
		},
		{
			title: 'House Area',
			dataIndex: 'houseArea',
			key: 'houseArea',
			sorter: true
		},
		{
			title: 'House Area',
			dataIndex: 'houseArea',
			key: 'houseArea',
			sorter: true
		},
		{
			title: 'Position',
			dataIndex: 'position',
			key: 'position',
			sorter: true
		},
		{
			title: 'User Role Name',
			dataIndex: 'userRoleName',
			key: 'userRoleName',
			sorter: true,
			sortKey: 'role.roleName'
		},
		{
			title: 'Fixed Tip Commission',
			dataIndex: 'fixedTipCommission',
			key: 'fixedTipCommission',
			sorter: true
		},
		{
			title: 'Income/Hr',
			dataIndex: 'incomePerHour',
			key: 'incomePerHour',
			sorter: true
		},
		{
			title: 'Fixed Tip Commission',
			dataIndex: 'fixedTipCommission',
			key: 'fixedTipCommission',
			sorter: true
		},
		{
			title: 'Tip Rate',
			dataIndex: 'tipRate',
			key: 'tipRate',
			sorter: true
		},
		{
			type: TABLE_COL_HANDLE_TYPE.DEFAULT,
			title: 'Wage/hr',
			dataIndex: 'wagePerHour',
			key: 'wagePerHour',
			sorter: true
		}
	]

	const rowClassName = (record) => {
		return record.isFWCExpired || record.isMASTExpired ? 'high-light-row' : ''
	}

	return (
		<GenericTable
			endpoint={endpoint}
			filtersConfig={filtersConfig}
			columnConfig={columnConfig}
			errorMessage="Failed to fetch employee information."
			noDataMessage="No employee information available"
			tableName="Employee Information"
			formType="EmployeeInfo"
			rowClassName={rowClassName}
			options={{
				topBatchEdit: role === ADMIN,
				topAddBtn: role === ADMIN,
				showAction: role === ADMIN
			}}
		/>
	)
}

export default EmployeeInfo
