// src/contexts/PropertiesContext.js
import React, { createContext, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { getCompanyProperty } from '@/api/company'
// Create context
export const PropertiesContext = createContext();

// Provider component
export const PropertiesProvider = ({ companyId, children }) => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchProperties = useCallback(async (companyId) => {
        if (!companyId || loading) return; // Ensure companyId is provided
        setLoading(true)
        const response = await getCompanyProperty(companyId).catch(err => {
            setError(err.message)
            setLoading(false);
        })
        console.log(response)
        if (response) {
            setProperties(response.content); // Set properties from response content
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (companyId) {
            fetchProperties(companyId); // Fetch properties when companyId changes
        }
    }, [companyId, fetchProperties]);

    const propertyList = useMemo(() => {
        return properties ? properties.map(v => ({label: v.storeName, value: v.id})) : []
    }, [properties])

    return (
        <PropertiesContext.Provider value={{ properties, propertyList, loading, error, fetchProperties }}>
            {children}
        </PropertiesContext.Provider>
    );
};

// Custom hook to use the PropertiesContext
export const useProperties = () => {
    const context = useContext(PropertiesContext);
    if (context === undefined) {
        throw new Error('useProperties must be used within a PropertiesProvider');
    }
    return context;
};
