import {useContext, useMemo} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
import CustomForm from '@/components/CustomeForm'
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons'
import {Modal, Form, Button, Row, Col, InputNumber, Select, message} from 'antd'
import dayjs from 'dayjs'
import service from '@/config/axios/index.js'
import {EmployeeInfoConfig, PropertyConfig} from './FormConfig'
import {HOUSE_AREA_DICT} from '@/utils/dict'
import './SubmitForm.scss'

const CONFIG = {
	Stock: PropertyConfig,
	EmployeeInfo: EmployeeInfoConfig,
	PropertyInfo: PropertyConfig,
}

const ShiftModal = ({
	isModalOpen, 
	setModalOpen, 
	companyId, 
	propertyId, 
	endpoint, 
	refreshList, 
	defaultFormData = {}, 
	formType, 
	initHouseAreaList = [],
	initPositionList = [],
	initRoleList = []
}) => {
	const [form] = Form.useForm()
	const {propertyList, properties} = useContext(PropertiesContext) // Fetch properties from context
	const formConfig = useMemo(() => {
		console.log(initHouseAreaList, propertyId,properties, 'initHouseAreaList')
		let storeName = ''
		if (propertyId) {
			let property = properties.find(v => v.id == propertyId)
			storeName = property?.storeName

		}
		return {
			form: form, // form instance
			initialValues: {shift_date: dayjs(), ...defaultFormData},
			colSpan: {span: 8},
			data: CONFIG[formType]({propertyList, properties, storeName, houseAreaList: initHouseAreaList, positionList: initPositionList, roleList: initRoleList}),
		}
	}, [formType, form, defaultFormData, propertyList, properties, propertyId])

	const handleOk = () => {
		form.submit()
	}
	const handleCancel = () => {
		setModalOpen(false)
	}

	const onSubmit = async val => {
		let formData = {
			companyID: companyId,
			propertyID: propertyId,
			...defaultFormData,
		}
		Object.keys(val).forEach(k => {
			if (val[k]?.$isDayjsObject) {
				formData[k] = val[k].format()
			} else if (k === 'houseAreaList') {
				let houseAreaList = {}
				val[k]?.forEach(w => {
					houseAreaList[w.houseArea] = w.value
				})
				formData['houseAreaAvailable'] = houseAreaList
			} else {
				formData[k] = val[k]
			}
		})

		try {
			if (!defaultFormData.id) {
				const response = await service.post({url: endpoint, data: formData})
				message.success('Record created successfully')
			} else {
				const response = await service.patch({url: endpoint, data: formData})
				message.success('Record edit successfully')
			}
			setModalOpen(false)
			refreshList && refreshList()
		} catch (err) {
			console.log(err)
			message.error('Record created failed')
		}
	}
	return (
		<Modal title="Info Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="submit-modal" width={1120}>
			<div className="generic-submit-form">
				<CustomForm {...formConfig} onFinish={onSubmit}>
					{formType === 'PropertyInfo' ? (
						<Form.List name="houseAreaList">
							{(fields, {add, remove}, {errors}) => (
								<Col span={24}>
									<h2>House Area Available</h2>
									{fields.map(({key, name, ...restFeild}) => {
										return (
											<div className="work-history-wrap" key={key}>
												<Row gutter={6}>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, 'houseArea']}
															label="House Area"
															rules={[{required: true, message: 'Please Select House Area.'}]}
															labelCol={{span: 12}}
															wrapperCol={{span: 12}}
														>
															<Select style={{width: '100%'}} placeholder="Select House Area" options={HOUSE_AREA_DICT} />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, 'value']}
															label="House Area value"
															rules={[{required: true, message: 'Please Input House Area value.'}]}
															labelCol={{span: 12}}
															wrapperCol={{span: 12}}
														>
															<InputNumber style={{width: '100%'}} placeholder="Please Input House Area value" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Button type="dashed" danger onClick={() => remove(name)} icon={<DeleteOutlined />}>
															Delete field
														</Button>
													</Col>
												</Row>
											</div>
										)
									})}
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => add()}
											style={{
												width: '60%',
											}}
											icon={<PlusOutlined />}
										>
											Add field
										</Button>
										<Form.ErrorList errors={errors} />
									</Form.Item>
								</Col>
							)}
						</Form.List>
					) : null}
				</CustomForm>
			</div>
		</Modal>
	)
}

export default ShiftModal
