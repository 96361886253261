import service from '@/config/axios/index.js'

export const getPropertySaleAll = async (companyId, propertyId)  => {
	return await service.get({
		url: `/api/company/${companyId}/property/${propertyId}/shift/all`,
	})
}
export const getPropertyStockAll = async (companyId, propertyId)  => {
	return await service.get({
		url: `/api/company/${companyId}/property/${propertyId}/stock/all`,
	})
}
export const getPropertyWorkHistoryAll = async (companyId, propertyId)  => {
	return await service.get({
		url: `/api/company/${companyId}/property/${propertyId}/work-history/all`,
	})
}

export const getRollAll = async (propertyId)  => {
	return await service.get({
		url: `/api/role/all?propertyId=${propertyId}`,
	})
}