import React, {useEffect, useState, useMemo, useContext} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
import {useNavigate, useParams} from 'react-router-dom'
import Shift from '@/components/Dashboard/Shift'
import Stock from '@/components/Dashboard/Stock'
import WorkHistory from '@/components/Dashboard/WorkHistory'
import EmployeeInfo from '@/components/Dashboard/EmployeeInfo';
import PropertyInfo from '@/components/Dashboard/PropertyInfo';
import Payroll from '@/components/Dashboard/Payroll';
import { useAuth } from '@/contexts/AuthContext';
import { ADMIN, MANAGER, SERVER } from '@/utils/constants'
import './PropertyPage.scss'

// Define tab
const TAB_DATA = [
	{
		name: 'Shift',
		key: 'shift',
		page: ['company', 'property'],
		auth: [ADMIN, MANAGER, SERVER]
	},
	{
		name: 'Stock',
		key: 'stock',
		page: ['company', 'property'],
		auth: [ADMIN, MANAGER, SERVER]
	},
	{
		name: 'Work History',
		key: 'workHistory',
		page: ['company', 'property'],
		auth: [ADMIN, MANAGER]
	},
	{
		name: 'Employee Info',
		key: 'employeeInfo',
		page: ['company', 'property'],
		auth: [ADMIN, MANAGER]
	},
	{
		name: 'Property',
		key: 'property',
		page: ['company'],
		auth: [ADMIN]
	},
	{
		name: 'Payroll',
		key: 'payroll',
		page: ['company', 'property'],
		auth: [ADMIN, MANAGER]
	},
]

const PropertyPage = () => {
	const navigate = useNavigate()
	const {companyId, propertyId} = useParams() // Extract companyId and propertyId from URL
	// calculate tabs by propertyId
	const {properties} = useContext(PropertiesContext) // Fetch properties from context
	const { role, propertyRole } = useAuth();
	const propertyInfo = useMemo(() => {
		let property = properties.find(v => v.id == propertyId)
		return property??{}
	}, [propertyId, properties])

	const tabs = useMemo(() => {
		const page = propertyId ? 'property' : 'company'
		return TAB_DATA.filter(v => v.auth.includes(role) || v.auth.includes(propertyRole[propertyId])).filter(v => v.page.includes(page))
	}, [propertyId, propertyRole, role])
	const [activeTab, setActiveTab] = useState('shift')
	const [loading, setLoading] = useState(true)

	// Reset data and loading state when activeTab changes
	useEffect(() => {
		if (role !== ADMIN && !propertyId) {
			navigate('/404')
		}
		setTimeout(() => {
			setLoading(false)
		}, 2000)
	}, [])

	if (loading) return <p>Loading...</p>

	const ComponentToRender = {
		shift: Shift,
		stock: Stock,
		workHistory: WorkHistory,
		employeeInfo: EmployeeInfo,
		property: PropertyInfo,
		payroll: Payroll
	  }[activeTab];

	return (
		<div className="new-property-page property-page">
			<div className="content-container">
				<div className="vertical-nav">
					<h3>Property Menu</h3>
					{tabs.map(tab => (
						<button key={tab.key} className={activeTab === tab.key ? 'active' : ''} onClick={() => setActiveTab(tab.key)}>
							{tab.name}
						</button>
					))}
				</div>
				<div className="main-content">
					{propertyId?<h2>Property Page - {propertyInfo.storeName}</h2>:null}
					<div className="tab-content">{ComponentToRender && <ComponentToRender companyId={companyId} propertyId={propertyId} />}</div>
				</div>
			</div>
		</div>
	)
}

export default PropertyPage
