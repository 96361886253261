// src/components/Auth/Login.js
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {LockOutlined, UserOutlined} from '@ant-design/icons'
import {Button, Form,Input} from 'antd'
import { useAuth } from '@/contexts/AuthContext';

const Login = () => {
	const { handleSubmit, loading } = useAuth();
	const navigate = useNavigate() // Use useNavigate for navigation

	return (
		<div className="login-page">
			<div className='login-form'>
				<h2>Login</h2>
				<Form name="login" onFinish={handleSubmit}>
					<Form.Item
						name="username"
						rules={[
							{
								required: true,
								message: 'Please input your Username!',
							},
						]}
					>
						<Input prefix={<UserOutlined />} placeholder="Username" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: 'Please input your Password!',
							},
						]}
					>
						<Input.Password prefix={<LockOutlined />} placeholder="Password" />
					</Form.Item>

					<Form.Item>
						<Button block type="primary" htmlType="submit" loading={loading}>
							Login
						</Button>
						<Button
							type="link"
							onClick={() => {
								navigate('/forgotpassword')
							}}
							style={{paddingLeft: 0}}
						>
							Forget Password
						</Button>
						or
						<Button
							type="link"
							onClick={() => {
								navigate('/register')
							}}
						>
							Register now!
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

export default Login
