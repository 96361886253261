import React, {useContext, useState} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
import {Form} from 'antd'
import GenericTable from './GenericTable'
import dayjs from 'dayjs'
import { mkConfig, generateCsv, download } from "export-to-csv";
import {HOUSE_AREA_DICT} from '@/utils/dict'

const Payroll = ({companyId, propertyId}) => {
	const [form] = Form.useForm()
	const endpoint = propertyId ? `/api/company/${companyId}/property/${propertyId}/work-history/generate-report` : `/api/company/${companyId}/work-history/generate-report`
	const {propertyList} = useContext(PropertiesContext) // Fetch properties from context
	const [startDate, setStartDate] = useState(dayjs().subtract(14, 'day').format("YYYY-MM-DD"))

	const filtersConfig = {
		form: form, // form instance
        initialValues: {
			propertyId: propertyId || undefined,
			endDate: dayjs() 
		},
		data: [
			...(!propertyId ? [
				{
					key: 'propertyId',
					label: 'Property Name',
					name: 'propertyId',
					$input: {
						type: 'select',
						placeholder: 'Please Select Property Name',
						options: propertyList,
					},
				},
			] : []),
			{
				key: 'houseArea',
				label: 'House Area',
				name: 'houseArea',
				$input: {
					type: 'select',
					placeholder: 'Select House Area',
					options: HOUSE_AREA_DICT,
					allowClear: true
				},
			},
			{
				key: 'endDate',
				label: 'End Date',
				name: 'endDate',
				$input: {
					type: 'datepicker',
					placeholder: 'End Date',
				},
				union: {
					target: 'endDate',
					event: 'onChange',
					callback(value, option) {
						if (value) {
							setStartDate(value.subtract(14, 'day').format("YYYY-MM-DD"))
						}
					}
				}
			}
		],
	}

	const columnConfig = [
		{
			title: 'ADP Employee Id',
			dataIndex: 'adpEmployeeId',
			key: 'adpEmployeeId',
			sorter: true
		},
		{
			title: 'Employee Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			sorter: true
		},
		{
			title: 'Store Name',
			dataIndex: 'propertyName',
			key: 'propertyName',
			sorter: true,
			sortKey: 'property.storeName'
		},
		{
			title: 'Property Code',
			dataIndex: 'propertyCode',
			key: 'propertyCode',
			sorter: true,
			sortKey: 'property.propertyCode'
		},
		{
			title: 'Total Paid Hours',
			dataIndex: 'totalPaidHours',
			key: 'totalPaidHours',
			sorter: true
		},
		{
			title: 'Tips/Hr',
			dataIndex: 'overallTipsPerHour',
			key: 'overallTipsPerHour',
			sorter: true
		},
		{
			title: 'Income/Hr',
			dataIndex: 'overallIncomePerHour',
			key: 'overallIncomePerHour',
			sorter: true
		},
		{
			title: 'Total Tips',
			dataIndex: 'totalTips',
			key: 'totalTips',
			sorter: true
		},
		{
			title: 'Week1 Basic Wage',
			dataIndex: 'week1BasicWage',
			key: 'week1BasicWage',
			sorter: true
		},
		{
			title: 'Week1 Overtime Hours',
			dataIndex: 'week1OvertimeHours',
			key: 'week1OvertimeHours',
			sorter: true
		},
		{
			title: 'Week1 Paid Break Hours',
			dataIndex: 'week1PaidBreakHours',
			key: 'week1PaidBreakHours',
			sorter: true
		},
		{
			title: 'Week1 Tips',
			dataIndex: 'week1Tips',
			key: 'week1Tips',
			sorter: true
		},
		{
			title: 'Week1 Tips/Hr',
			dataIndex: 'week1TipsPerHour',
			key: 'week1TipsPerHour',
			sorter: true
		},
		{
			title: 'Week1 Income/Hr',
			dataIndex: 'week1IncomePerHour',
			key: 'week1IncomePerHour',
			sorter: true
		},
		{
			title: 'Week1 TotalHours',
			dataIndex: 'week1TotalHours',
			key: 'week1TotalHours',
			sorter: true
		},
		{
			title: 'Week1 Worked Hours',
			dataIndex: 'week1WorkedHours',
			key: 'week1WorkedHours',
			sorter: true
		},
		{
			title: 'Week1 LiquorSales',
			dataIndex: 'week1LiquorSales',
			key: 'week1LiquorSales',
			sorter: true
		},
		{
			title: 'Week2 Basic Wage',
			dataIndex: 'week2BasicWage',
			key: 'week2BasicWage',
			sorter: true
		},
		{
			title: 'Week2 Overtime Hours',
			dataIndex: 'week2OvertimeHours',
			key: 'week2OvertimeHours',
			sorter: true
		},
		{
			title: 'Week2 Paid Break Hours',
			dataIndex: 'week2PaidBreakHours',
			key: 'week2PaidBreakHours',
			sorter: true
		},
		{
			title: 'Week2 Tips',
			dataIndex: 'week2Tips',
			key: 'week2Tips',
			sorter: true
		},
		{
			title: 'Week2 Tips/Hr',
			dataIndex: 'week2TipsPerHour',
			key: 'week2TipsPerHour',
			sorter: true
		},
		{
			title: 'Week2 Income/Hr',
			dataIndex: 'week2IncomePerHour',
			key: 'week2IncomePerHour',
			sorter: true
		},
		{
			title: 'Week2 TotalHours',
			dataIndex: 'week2TotalHours',
			key: 'week2TotalHours',
			sorter: true
		},
		{
			title: 'Week2 Worked Hours',
			dataIndex: 'week2WorkedHours',
			key: 'week2WorkedHours',
			sorter: true
		},
		{
			title: 'Week2 LiquorSales',
			dataIndex: 'week2LiquorSales',
			key: 'week2LiquorSales',
			sorter: true
		},
		{
			title: 'Total Liquor Sales',
			dataIndex: 'totalLiquorSales',
			key: 'totalLiquorSales',
			sorter: true
		},
		{
			title: 'Total Liquor Sales',
			dataIndex: 'totalLiquorSales',
			key: 'totalLiquorSales',
			sorter: true
		}
	]

    const defaultParams = {
		endDate: dayjs().format('YYYY-MM-DD')
	}

	const downloadActions = (data, tableConfig) => {
		const columnHeaders = tableConfig.filter(v => !v.hidden).map(k => {
			let title = ''
			let key = k
			if (typeof k === 'object') {
				title = k.dataIndex.replace(/([A-Z])/g, function (s) {
					return ' ' + s
				})
				key = k.dataIndex
			} else {
				title = k.replace(/([A-Z])/g, function (s) {
					return ' ' + s
				})
			}
			title = title[0].toUpperCase() + title.substr(1)
			return {
				displayLabel: title,
				key
			}
		})
		const csvConfig = mkConfig({ columnHeaders, filename: 'data' });
		const csv = generateCsv(csvConfig)(data);
		download(csvConfig)(csv)
	}

	return (
		<GenericTable
			endpoint={endpoint}
			filtersConfig={filtersConfig}
			columnConfig={columnConfig}
			errorMessage="Failed to fetch Payroll data."
			noDataMessage="No Payroll available"
			tableName={`Payroll——(Start Date: ${startDate})`}
			formType="Payroll"
            defaultParams={defaultParams}
			downloadActions={downloadActions}
            options={{
                defaultFetch: false,
                topAddBtn: false,
                showAction: false
            }}
		/>
	)
}

export default Payroll