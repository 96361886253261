import * as React from 'react';
import { Form, Col, Row } from 'antd';
import { adapterItemConfig, adapterConfig } from './parse';
import { WrapInputElement } from './wrapElement';
import renderMemos from './renderMemos';
import useSelectOprate from './useSelectOprate';
import 'antd/dist/reset.css';
import './index.scss'

const defaultLabelCol = {
  span: 8
}

const defaultWrapperCol= {
  span: 16
}

const defaultColSpan = {
  span: 8,
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 6,
  xxl: 4
}

function CustomeForm(props) {
  const [_form] = Form.useForm();
  const [selectOp] = useSelectOprate();
  const { data, state, gutter = 12, colSpan = defaultColSpan, ...restField } = props;
  const formProperty = restField;
  const form = formProperty.form || _form;
  const [status, setStatus] = React.useState(state || {});

  const setMystate = (params) => {
    const newState = { ...status, ...params };
    setStatus(newState);
  };

  const realyData =
    typeof data === 'function' ? data(status, setMystate) : data;

  form.operate = { selectOp };
  if (!formProperty.form) {
    formProperty.form = form;
  }

  const formContext = {
    getForm() {
      return {
        ...form,
        setOptions(id, options) {
          selectOp.setOptions(id, options);
        },
      };
    },
  };

  const { fields, directUnions, flatFormNames, flatChilds } = adapterConfig(
    realyData,
    form.operate
  );

  const formWatcher = {};
  /* eslint-disable */
  for (let ii = 0; ii < directUnions.length; ii++) {
    const [eventName, name] = directUnions[ii];
    if (flatFormNames.indexOf(name) > -1) {
      formWatcher[name] = Form.useWatch(name, form);
    }
  }
  /* eslint-enable */

  const fieldsData = renderMemos(fields, {
    flatFormNames,
    formWatcher,
    formContext,
    status,
  });
  console.log(fieldsData)
  return (
    <Form className="custome-form" labelCol={defaultLabelCol} wrapperCol={defaultWrapperCol} {...formProperty}>
      <Row gutter={gutter}>
        {fieldsData.map((field) => {
          return (<Col {...colSpan} key={field.key}>
            {
              field.type == 'direct-union-callback' ? (
                field.memo
              ) : React.isValidElement(field) ? (
                field
              ) : (
                <WrapInputElement {...field} key={field.key}/>
              )
            }
          </Col>)
        }
        )}
        {props.children}
      </Row>
    </Form>
  );
}

// 为了方便，不用单独引用antd
export { Form } from 'antd';

export function union(name, callback) {
  let evt = '';
  return {
    type: 'direct-union-callback',
    name,
    event: evt,
    directUnionCallback: callback,
  };
}

const FormItem = (props) => {
  const form = Form.useFormInstance();
  const res = adapterItemConfig({ ...props }, [{ ...props }], form.operate);
  return <WrapInputElement {...res.current} />;
};

export function formItem(itemConfig) {
  return <FormItem {...itemConfig} />;
}

export function formList(listConfig) {
  const formListConfig = { ...listConfig };
  return {
    render(callback) {
      return (
        <Form.List {...formListConfig}>
          {(fields, { add, remove, move }, { errors }) => {
            return callback.apply(this, [
              fields,
              { add, remove, move },
              { errors },
            ]);
          }}
        </Form.List>
      );
    },
  };
}

export default CustomeForm;