import {useContext, useEffect, useState} from "react"
import {useParams} from 'react-router-dom'
import {PropertiesContext} from "@/contexts/PropertiesContext"
import { useAuth } from '@/contexts/AuthContext';
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons"
import {Modal, Form, Button, Row, Col, DatePicker, InputNumber, Select, message, Input, Checkbox} from "antd"
import dayjs from "dayjs"
import service from "@/config/axios/index.js"
import {SHIFT_TYPE_DICT, HOUSE_AREA_DICT} from "@/utils/dict"
import {getCompanyEmployeeInfo} from "@/api/company"
import "./SubmitForm.scss"
import { useMemo } from 'react'
import { SERVER } from '@/utils/constants';

const rates = ['0.01', '0.05', '0.10', '0.25', '1.00', '2.00', '5.00', '10.00', '20.00', '50.00', '100.00']

const ShiftModal = ({
	isModalOpen,
	setModalOpen,
	companyId,
	propertyId,
	endpoint,
	refreshList,
	defaultFormData = {},
	initShiftTypeList = [],
	initHouseAreaList = [],
}) => {
	const [form] = Form.useForm()
	const [formData] = useState({
		shiftDate: dayjs(),
		...defaultFormData
	})
	const [propertyName, setPropertyName] = useState("")
	const [FOHemployeeList, setFOHEmployeeList] = useState([])
	const [BOHemployeeList, setBOHEmployeeList] = useState([])
	const [shiftTypeList, setShiftTypeList] = useState(initShiftTypeList)
	const [houseAreaList, setHouseAreaList] = useState(initHouseAreaList)
	const {propertyList, properties} = useContext(PropertiesContext) // Fetch properties from context
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [c, forceUpdate] = useState(0);
	const {propertyId: curPropertyId} = useParams()
	console.log(propertyId)
	const { role, propertyRole } = useAuth();
	const propertyInfo = useMemo(() => {
		let property = properties.find(v => v.id == curPropertyId)
		return property??{}
	}, [curPropertyId, properties])
	const disableSubmit = useMemo(() => {
		return propertyRole[propertyId] === SERVER && defaultFormData.isSubmitted
	}, [propertyRole, propertyId, defaultFormData])

	useEffect(() => {
		// data init
		let property = properties.find(v => v.id == defaultFormData.propertyId)
		if (property) {
			setPropertyName(property.storeName)
		}
		getEmployeeNameList(defaultFormData.propertyId)
	}, [defaultFormData.propertyId])

	useEffect(() => {
		// initialCash while modal show
		if (curPropertyId && !formData.initialCash) {
			let property = properties.find(v => v.id == curPropertyId) ?? {}
			form.setFieldValue('initialCash', property.initialCash || 0)
		}
	}, [])

	const getEmployeeNameList = async propertyId => {
		let res = await getCompanyEmployeeInfo(companyId, {propertyId})
		if (res.content) {
			const list = res.content.map(v => ({
				value: v.id,
				label: v.name,
				houseArea: v.houseArea
			}))
			const FOHemployeeList = list.filter(v => v.houseArea === 'FOH')
			const BOHemployeeList = list.filter(v => v.houseArea === 'BOH')
			setFOHEmployeeList(FOHemployeeList)
			setBOHEmployeeList(BOHemployeeList)
		}
	}

	const propertyChange = value => {
		let shiftTypeList = []
		let houseAreaList = []
		let property = {}
		if (value) {
			property = properties.find(v => v.id === value)
			if (property && property.shiftTypeAvailable) {
				shiftTypeList = SHIFT_TYPE_DICT.filter(v => property.shiftTypeAvailable.includes(v.value))
			}
			if (property && property.houseAreaAvailable) {
				let keys = Object.keys(property.houseAreaAvailable)
				houseAreaList = HOUSE_AREA_DICT.filter(v => keys.includes(v.value))
			}
		}
		getEmployeeNameList(value)
		setHouseAreaList(houseAreaList)
		setShiftTypeList(shiftTypeList)
		setTimeout(() => {
			form.setFieldValue("shiftType")
			form.setFieldValue("FOH_workhistory")
			form.setFieldValue("BOH_workhistory")
			if (!form.getFieldValue('initialCash') && property) {
				form.setFieldValue("initialCash", property.initialCash || 0)
				forceUpdate(c => ++c)
			}
		}, 100)
	}

	const handleOk = () => {
		setIsSubmitted(false)
		form.submit()
	}

	const handleSubmit = () => {
		setIsSubmitted(true)
		form.submit()
	}
	const handleCancel = () => {
		setModalOpen(false)
	}

	const onSubmit = async val => {
		console.log(val, "***")
		let formData = {
			propertyId,
			isSubmitted,
		}
		const commentKey = ['employeeNote', 'complaints', 'others']
		Object.keys(val).forEach(k => {
			if (val[k]?.$isDayjsObject) {
				formData[k] = val[k].format()
			} else if (commentKey.includes(k) && val[k] && val[k].trim()) {
				if (formData.comment) {
					formData.comment[k] = val[k]
				} else {
					formData.comment = {
						[k]: val[k]
					}
				}
			} else {
				formData[k] = val[k]
			}
		})
		
		formData.workHistories = (formData.FOH_workhistory || []).concat(formData.BOH_workhistory || [])
		try {
			if (!defaultFormData.id) {
				const response = await service.post({url: endpoint, data: formData})
				message.success("Record created successfully")
			} else {
				const response = await service.patch({url: endpoint + "/" + defaultFormData.id, data: formData})
				message.success("Record edit successfully")
			}
			setModalOpen(false)
			refreshList && refreshList()
		} catch (err) {
			console.log(err)
			message.error("Record created failed")
		}
	}

	const changeCash = (k, value) => {
		form.setFieldValue(['cashDetails', k], value)
		forceUpdate(c => ++c)
	}

	const changeInitCash = (value) => {
		let val =  Number(((value || 0)).toFixed(2))
		form.setFieldValue('initialCash', val)
		forceUpdate(c => ++c)
	}

	const calcTotal = useMemo(() => {
		const cashDetails = form.getFieldValue('cashDetails')
		const initCash = form.getFieldValue('initialCash')
		let cash = {
			initCash: initCash|| 0,
			totalCash: 0,
			missionCash: 0
		}
		if (cashDetails) {
			Object.keys(cashDetails).forEach(k => {
				cash[k] = Number(((cashDetails[k] || 0) * k).toFixed(2))
				cash.totalCash += cash[k]
			})
			cash.totalCash = Number((cash.totalCash).toFixed(2))
			cash.missionCash = Number((cash.totalCash - cash.initCash).toFixed(2))
		}
		return cash
	}, [c])

	return (
		<Modal
			title="Info Modal"
			open={isModalOpen}
			okText="Save As Draft"
			onOk={handleOk}
			onCancel={handleCancel}
			className="submit-modal"
			width={1120}
			okButtonProps={{ disabled: disableSubmit }}
			footer={(_, {OkBtn, CancelBtn}) => (
				<>
					<CancelBtn />
					<OkBtn/>
					<Button type="primary" onClick={handleSubmit} disabled={disableSubmit}>
						Submit
					</Button>
				</>
			)}
		>
			<div className="generic-submit-form">
				{propertyName ? <h2>{propertyName}</h2> : null}
				<Form className="custome-form" labelCol={{span: 8}} wrapperCol={{span: 16}} form={form} initialValues={formData} onFinish={onSubmit}>
					<div className="top-form">
						<div className="top-form-item">
							<Row gutter={12}>
								<Col span={12}>
									<Form.Item
										name="propertyId"
										label="Property Name"
										rules={[{required: true, message: "Please Select Property Name."}]}
										labelCol={{span: 11}}
										wrapperCol={{span: 13}}
									>
										{
											curPropertyId 
											? 
											<div>
												<span>{propertyInfo.storeName}</span>
												<Select style={{display: 'none'}} placeholder="Select Property Name" options={propertyList} />
											</div>
											:<Select style={{width: "100%"}} placeholder="Select Property Name" options={propertyList} onChange={propertyChange} />
										}
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="shiftSupervisor"
										label="Shift Supervisor"
										rules={[{required: true, message: "Please Input Shift Supervisor."}]}
										labelCol={{span: 11}}
										wrapperCol={{span: 13}}
									>
										<Input placeholder="Please Input Shift Supervisor" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="shiftType"
										label="Shift Type"
										rules={[{required: true, message: "Please Select Shift Type."}]}
										labelCol={{span: 11}}
										wrapperCol={{span: 13}}
									>
										<Select style={{width: "100%"}} placeholder="Select Shift Type" options={shiftTypeList} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="shiftDate"
										label="Shift Date"
										rules={[{required: true, message: "Please Select Shift Date."}]}
										labelCol={{span: 11}}
										wrapperCol={{span: 13}}
									>
										<DatePicker
											format={"YYYY-MM-DD HH:mm"}
											showTime
											placeholder="Select Shift Date"
											maxDate={dayjs(dayjs().format("YYYY-MM-DD"))}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="cashSale" label="Cash Sale" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<InputNumber style={{width: "100%"}} placeholder="Please Input Cash Sale" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="cashTip" label="Cash Tip" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<InputNumber style={{width: "100%"}} placeholder="Please Input Cash Tip" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="creditSale" label="Credit Sale" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<InputNumber style={{width: "100%"}} placeholder="Please Input Credit Sale" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="creditTip" label="Credit Tip" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<InputNumber style={{width: "100%"}} placeholder="Please Input Credit Tip" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="employeeNote" label="Employee Notes" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<Input.TextArea placeholder="Please Input Employee Notes" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="complaints" label="Complaints" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<Input.TextArea placeholder="Please Input Complaints" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="others" label="Other Comment" labelCol={{span: 11}} wrapperCol={{span: 13}}>
										<Input.TextArea placeholder="Please Input Other Comment" />
									</Form.Item>
								</Col>
							</Row>
						</div>
						<div className="top-form-item" style={{paddingLeft: '10px'}}>
							<table className='top-form-table'>
								<thead>
									<tr>
										<td>Unit($)</td>
										<td>Number</td>
										<td>Value</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Initial Cash</td>
										<td></td>
										<td>
											<Form.Item name="initialCash" labelCol={{span: 0}} wrapperCol={{span: 24}}>
												<InputNumber style={{width: "100%"}} placeholder="Initial Cash" onChange={changeInitCash}/>
											</Form.Item>
										</td>
									</tr>
									{
										rates.map(rate => {
											return (
												<tr key={rate}>
													<td>{rate}</td>
													<td>
														<Form.Item name={["cashDetails", rate]} labelCol={{span: 0}} wrapperCol={{span: 24}}>
															<InputNumber style={{width: "100%"}} placeholder="Please Input" onChange={(val) => {changeCash(rate, val)}}/>
														</Form.Item>
													</td>
													<td>{calcTotal[rate] || 0}</td>
												</tr>
											)
										})
									}
									<tr>
										<td>Cash Total</td>
										<td></td>
										<td>{calcTotal.totalCash || 0}</td>
									</tr>
									<tr>
										<td>Cash Out</td>
										<td></td>
										<td>{calcTotal.missionCash || 0}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<Row gutter={12}>
						<Form.List name="FOH_workhistory">
							{(fields, {add, remove}, {errors}) => (
								<Col span={24}>
									<h2>FOH Work Histories</h2>
									{fields.map(({key, name, ...restFeild}) => {
										return (
											<div className="work-history-wrap" key={key}>
												<Row gutter={6}>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "employeeId"]}
															label="Employee Name"
															rules={[{required: true, message: "Please Select Employee Name."}]}
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<Select style={{width: "100%"}} placeholder="Select Employee Name" options={FOHemployeeList} />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "workedHours"]}
															label="Worked Hours"
															rules={[{required: true, message: "Please Input Worked Hours."}]}
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Worked Hours" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "paidBreakHours"]}
															label="Paid Break Hours"
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Paid Break Hours" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "unpaidBreakHours"]}
															label="Unpaid Break Hours"
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Unpaid Break Hours" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "liquorSale"]}
															label="Liquor Sale"
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Liquor Sale" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
																{...restFeild}
															name={[name, "comment"]} 
															label="Comment" 
															labelCol={{span: 10}} 
															wrapperCol={{span: 14}}
														>
															<Input.TextArea placeholder="Please Input Comment" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "late"]} 
															label="Late" 
															labelCol={{span: 10}} 
															wrapperCol={{span: 14}}
															valuePropName="checked"
														>
															<Checkbox />
														</Form.Item>
													</Col>
												</Row>
												<Button type="dashed" danger onClick={() => remove(name)} icon={<DeleteOutlined />}>
													Delete field
												</Button>
											</div>
										)
									})}
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => add({ houseArea: 'FOH' })}
											style={{
												width: "60%",
											}}
											icon={<PlusOutlined />}
										>
											Add FOH Workhistory
										</Button>
										<Form.ErrorList errors={errors} />
									</Form.Item>
								</Col>
							)}
						</Form.List>
					</Row>
					<Row gutter={12}>
						<Form.List name="BOH_workhistory">
							{(fields, {add, remove}, {errors}) => (
								<Col span={24}>
									<h2>BOH Work Histories</h2>
									{fields.map(({key, name, ...restFeild}) => {
										return (
											<div className="work-history-wrap" key={key}>
												<Row gutter={6}>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "employeeId"]}
															label="Employee Name"
															rules={[{required: true, message: "Please Select Employee Name."}]}
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<Select style={{width: "100%"}} placeholder="Select Employee Name" options={BOHemployeeList} />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "workedHours"]}
															label="Worked Hours"
															rules={[{required: true, message: "Please Input Worked Hours."}]}
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Worked Hours" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "paidBreakHours"]}
															label="Paid Break Hours"
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Paid Break Hours" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "unpaidBreakHours"]}
															label="Unpaid Break Hours"
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Unpaid Break Hours" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "liquorSale"]}
															label="Liquor Sale"
															labelCol={{span: 10}}
															wrapperCol={{span: 14}}
														>
															<InputNumber style={{width: "100%"}} placeholder="Please Input Liquor Sale" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
																{...restFeild}
															name={[name, "comment"]} 
															label="Comment" 
															labelCol={{span: 10}} 
															wrapperCol={{span: 14}}
														>
															<Input.TextArea placeholder="Please Input Comment" />
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item
															{...restFeild}
															name={[name, "late"]} 
															label="Late" 
															labelCol={{span: 10}} 
															wrapperCol={{span: 14}}
															valuePropName="checked"
														>
															<Checkbox />
														</Form.Item>
													</Col>
												</Row>
												<Button type="dashed" danger onClick={() => remove(name)} icon={<DeleteOutlined />}>
													Delete field
												</Button>
											</div>
										)
									})}
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => add({ houseArea: 'BOH' })}
											style={{
												width: "60%",
											}}
											icon={<PlusOutlined />}
										>
											Add BOH Workhistory
										</Button>
										<Form.ErrorList errors={errors} />
									</Form.Item>
								</Col>
							)}
						</Form.List>
					</Row>
				</Form>
			</div>
		</Modal>
	)
}

export default ShiftModal
