import React from 'react';
import { Form } from "antd"
import GenericTable from './GenericTable';
import { rangeNumberRule } from '@/components/CustomeForm/rules'

const Stock = ({ companyId, propertyId }) => {
  const [form] = Form.useForm()

  const endpoint = propertyId
    ? `/api/company/${companyId}/property/${propertyId}/stock`
    : `/api/company/${companyId}/stock`;

  const filtersConfig = {
		form: form, // form instance
		data: [
			{
				key: 'search',
				label: 'search',
				name: 'search',
				$input: {
					type: 'text', 
          placeholder: 'Please Input Search'
				}
			},
			{
				key: 'needBackfill',
				label: 'Need Backfill',
				name: 'needBackfill',
				$input: {
					type: 'select', 
          placeholder: 'Need Backfill or not',
          options: [{ value: 'true', label: 'true' }, { value: 'false', label: 'false' }]
				}
			},
			{
				key: 'activeOrNot',
				label: 'Active or Not',
				name: 'activeOrNot',
				$input: {
					type: 'select', 
          placeholder: 'Active or Not',
          options: [{ value: 'true', label: 'true' }, { value: 'false', label: 'false' }]
				},
			},
			{
				key: 'propertyName',
				label: 'Property Name',
				name: 'propertyName',
				$input: {
					type: 'select', 
          placeholder: 'Please Select Property Name',
          options: [{ value: 'Bellevue', label: 'Bellevue' }, { value: 'Downtown', label: 'Downtown' }]
				},
			},
      {
				key: 'minimumStock',
				label: 'minimumStock',
				name: '_minimumStock',
				$input: {
					type: 'rangenumber', 
          placeholder: 'minimumStock'
				},
				rules: [rangeNumberRule]
			},
		]
  }

  // Specify columns to hide by default
  const excludeColumns = ['id','companyId', 'propertyId']
  const editableColumns = ['minimumStock', 'details', 'productCategory', 'productName', 'currentStock']; 
  const formColumns = ['productName', 'productCategory', 'details',	'supplierId',	'minimumStock',	'currentStock', 'needToRefill','active','propertyName'];

  const validationRules = {
    createdAt: {
      required: true,
      type: 'date',
    },
    minimumStock: {
      required: true,
      type: 'number',
    }
  }; 

  return (
    <GenericTable
      endpoint={endpoint}
      filtersConfig={filtersConfig}
      // columns={columns}
      excludeColumns={excludeColumns} // Pass excludeColumns
      errorMessage="Failed to fetch stock information."
      noDataMessage="No stock information available"
      tableName="Stock"
      formType="Stock"
      editableColumns={editableColumns}
      validationRules={validationRules}
      formColumns = {formColumns}
    />
  );
};

export default Stock;
